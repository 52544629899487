<template>
    <cluji-card class="text-center" no-header :foldable="false">
        <h1>Bonjour {{ user.utilisateur.prenom }} !</h1>
        <height-fade-transition>
            <b-row v-if="isAncien() && user.utilisateur.ancienWantsRevalidation === null">
                <b-col xl="8" lg="10" offset-xl="2" offset-lg="1" class="border-warning text-warning">
                    <p>
                        Votre statut de Clujiste a été désactivé. Si vous vous êtes ré-inscrit au Cluji pour cette année,
                        vous pouvez demander à ce que votre compte soit ré-activé en cliquant sur cet élégant bouton :
                    </p>
                    <b-button variant="secondary" @click="askForAccountValidation">J'ai payé ma cotisation</b-button>
                </b-col>
            </b-row>
        </height-fade-transition>
        <b-row class="mt-4" v-if="infos.permanences"> <!-- Check if loading is finished -->
            <b-col xl="6" v-if="isAnyAsso()">
                <router-link :to="{name: 'dashboard_emprunts'}">
                    <b-card text-variant="white" class="inner-card mt-3"
                            :style="{'background-color': colors.jeux.background, '--border-color': colors.jeux.border}">
                        <h1 class="text-white mb-0">Emprunts</h1>
                        <hr class="m-3"/>
                        <font-awesome-icon icon="scroll" class="big-icon m-3"/>
                        <ul class="mb-0 mt-1">
                            <li>Vous avez {{ infos.emprunts.now }} emprunt{{ plural(infos.emprunts.now) }} en cours</li>
                            <li>Vous avez {{ infos.emprunts.next }} emprunt{{ plural(infos.emprunts.next) }} à venir</li>
                        </ul>
                    </b-card>
                </router-link>
            </b-col>
            <b-col xl="6">
                <router-link :to="{name: 'dashboard_list_evenements'}">
                    <b-card text-variant="white" class="inner-card mt-3"
                            :style="{'background-color': colors.evenements.background, '--border-color': colors.evenements.border}">
                        <h1 class="text-white mb-0">Événements</h1>
                        <hr class="m-3"/>
                        <font-awesome-icon icon="calendar-day" class="big-icon m-3"/>
                        <ul class="mb-0 mt-1">
                            <li>
                                Vous participez à {{ infos.evenements.participations }}
                                événement{{ plural(infos.evenements.participations) }} en cours ou à venir
                            </li>
                            <li v-if="infos.evenements.next">
                                Prochain événement : {{ infos.evenements.next.nom }}
                                {{ formatDateTime(infos.evenements.next.dateDebut.date).toLowerCase() }}
                            </li>
                        </ul>
                    </b-card>
                </router-link>
            </b-col>
            <b-col xl="6" v-if="isCluji()">
                <router-link :to="{name: 'dashboard_reservations'}">
                    <b-card text-variant="white" class="inner-card mt-3"
                            :style="{'background-color': colors.local.background, '--border-color': colors.local.border}">
                        <h1 class="text-white mb-0">Local</h1>
                        <hr class="m-3"/>
                        <font-awesome-icon icon="place-of-worship" class="big-icon m-3"/>
                        <p class="mb-0 mt-1">
                            Vous avez {{ infos.local.next }} réservation{{ plural(infos.local.next) }} à venir
                        </p>
                    </b-card>
                </router-link>
            </b-col>
            <b-col xl="6">
                <router-link :to="{name: 'dashboard_calendar'}">
                    <b-card text-variant="white" class="inner-card mt-3"
                            :style="{'background-color': colors.permanences.background, '--border-color': colors.permanences.border}">
                        <h1 class="text-white mb-0">Permanences</h1>
                        <hr class="m-3"/>
                        <font-awesome-icon :icon="['far', 'calendar-alt']" class="big-icon m-3"/>
                        <p class="mb-0 mt-1">
                            <span v-if="infos.permanences.next">
                                La prochaine permanence sera
                                {{ formatDateTime(infos.permanences.next.dateDebut.date).toLowerCase() }}
                            </span>
                            <span v-else>
                                Il n'y a pas de permanence prochainement
                            </span>
                        </p>
                    </b-card>
                </router-link>
            </b-col>
        </b-row>
        <!--        <p>-->
        <!--            <img src="https://thecatapi.com/api/images/get?format=src&type=gif" alt="cat"/>-->
        <!--        </p>-->
    </cluji-card>
</template>

<script>
    import {
        isCluji,
        isAnyAsso,
        isAncien,
        refreshSession
    }                       from '@/util/auth';
    import {apiPath}        from '@/util/http';
    import {plural}         from '@/util/text';
    import {formatDateTime} from '@/util/date';
    import alert            from '@/util/alert';
    import colors           from '@/util/colors';

    import {mapState} from 'vuex';

    const HeightFadeTransition = () => import('@/components/HeightFadeTransition');
    const ClujiCard            = () => import('@/components/ClujiCard');

    export default {
        name: 'DashboardHome',
        components: {HeightFadeTransition, ClujiCard},
        data: () => ({
            colors,
            infos: {}
        }),
        computed: {
            ...mapState({user: 'user'})
        },
        methods: {
            plural,
            formatDateTime,
            isCluji,
            isAnyAsso,
            isAncien,
            loadData() {
                alert.loading();
                this.axios.get(apiPath('user_infos_homepage'))
                    .then(response => this.infos = response.data)
                    .catch(() => this.$toaster.error("Impossible de charger le détail de la page d'accueil"))
                    .finally(alert.stopLoading);
            },
            askForAccountValidation() {
                alert.loading();
                this.axios.post(apiPath('clujiste_ask_revalidate'))
                    .then(refreshSession)
                    .then(() => this.$toaster.success('Demande prise en compte'))
                    .catch(() => this.$toaster.error("Erreur dans l'enregistrement de votre demande"))
                    .finally(alert.stopLoading);

            }
        },
        mounted() {
            this.loadData();
        }
    };
</script>

<style scoped>
    h1.text-white {
        font-weight: lighter;
    }

    .big-icon {
        font-size: 3rem;
    }

    .inner-card {
        min-height: 255px;
        text-shadow: 0 0 0.25rem var(--border-color, black);
        filter: drop-shadow(0 0 0.25rem var(--border-color, black));
    }

    .inner-card:hover {
        filter: drop-shadow(0 0 0.5rem var(--border-color, black));
    }

    .inner-card .big-icon {
        filter: drop-shadow(0 0 0.25rem var(--border-color, black));
    }

    .inner-card ul {
        padding: 0;
    }

    .inner-card ul li {
        list-style-position: inside;
    }

    .border-warning {
        border-radius: 1rem;
        border: 1px solid #fb63405F;
        padding: 1rem;
        background-color: #fb63400F;
    }
</style>