export default {
    evenements: {
        background: '#cd0000',
        border: '#b90000'
    },
    jeux: {
        background: '#00d7e6',
        border: '#00b5c3'
    },
    livres: {
        background: '#ffc500',
        border: '#e5b100'
    },
    jdrs: {
        background: '#e76300',
        border: '#bc4e00'
    },
    local: {
        background: '#00c306',
        border: '#009a05'
    },
    permanences: {
        background: '#7900e1',
        border: '#6500c3'
    },
    calendrier: {
        background: '#244ecb'
    },
    utilisateurs: {
        background: '#fa02ff'
    }
};